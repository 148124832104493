/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { object } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import mbpLogger from 'mbp-logger';
import ModalWrapper from '../../../../Common/ModalWrapper/ModalWrapper';
import ModalContent from '../../../../Common/ModalWrapper/Partials/ModalContent';
import LeftMenu from '../../../../../../../components/Header/components/LeftMenu/LeftMenu';
import ConnectedCart from '../../../../../../../components/GraphqlComponents/GraphqlHeader/Cart';
import BrandLogo from   './Partials/BrandLogo';

const styles = (theme) => ({
    headerBlock: {
        // CSS Grid
        alignSelf: 'center',
        justifySelf: 'center',

        // Flexbox
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        textAlign: 'center',
        fontSize: '14px',
        letterSpacing: '0.6px',
        textTransform: 'lowercase',
        color: theme.palette.mobileMenuColor,
        cursor: 'pointer',
        textDecoration: 'none',
        height: '42px',
        fontFamily: theme.typography?.fontFamily,
    },
    menu: {
        // CSS Grid
        gridRow: '1',
        gridColumn: '1',

        // Button Overrides
        appearance: 'none',
        border: 'none',
        background: 'none',
        fontSize: '10px',
        fontFamily: theme.typography?.secondaryFontFamily,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        letterSpacing: '.2px',
        borderRadius: '4px',
        '& img': {
            width: '24px',
            marginBottom: '3px',
        },
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    logo: {
        // CSS Grid
        gridRow: '1',
        gridColumn: '3',

        width: 'auto',
        height: '100%',

        '& img': {
            width: '100%',
            maxWidth: '174px',
        },
    },
});

const CheckoutHeaderMobile = ({
    block, brand, classes,
}) => {
    const blockKey = Object.keys(block)[0];
    const blockObj = block[blockKey];

    if (!blockObj) return null;

    const [isOpen, setModal] = useState(false);
    const openModal = () => {
        setModal(true);
    };
    const closeModal = () => {
        setModal(false);
    };
    const modalContent = <ModalContent closeModal={closeModal} />;
    switch (blockKey) {
        case 'menu':
            return (
                <LeftMenu
                    menu={blockObj.menu_ref?.[0]?.menu_blocks || 'default left menu'}
                    brand={brand}
                    menuIcon={{
                        name: blockObj.name,
                        url: blockObj.menu_icon?.url,
                        description: blockObj.name,
                        className: `${classes.headerBlock} ${classes.menu}`,
                    }}
                />
            );

        case 'logos':
            return (
                <>
                    <div className={`${classes.headerBlock} ${classes.logo}`}>
                        <Button
                            id="btn-logo-brand"
                            name="logoBrandButton"
                            data-testid="btn-logo-brand"
                            className={classes.logoBrandBtn}
                            onClick={openModal}
                        >
                            <BrandLogo withLink block={blockObj} />
                        </Button>
                    </div>
                    <ModalWrapper
                        modalTitle="LEAVE CHECKOUT"
                        modalContent={modalContent}
                        isOpen={isOpen}
                        onCloseModal={closeModal}
                        btnClose={false}
                    />
                </>
            );

        case 'widgets':
            if (blockObj.name === 'Shopping Cart') {
                return (
                    <ConnectedCart
                        image={blockObj.icon && blockObj.icon?.url ? blockObj.icon?.url : null}
                        name={blockObj.link?.title}
                        linkHref={blockObj.link?.href}
                        description={blockObj.link?.title}
                        type="mobile"
                    />
                );
            }
            break;
        default:
            mbpLogger.logError({
                message: 'Unknown header block type in MobileHeaderBlock',
                brand,
                block: blockObj,
            });
            return null;
    }
    return null;
};

CheckoutHeaderMobile.propTypes = {
    block: object.isRequired,
    classes: object.isRequired,
    brand: object.isRequired,
};

export default withStyles(styles)(CheckoutHeaderMobile);
