/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
    object,
    string,
    shape,
    func,
} from 'prop-types';
import { UIDReset, UIDConsumer } from 'react-uid';
import withStyles from '@material-ui/core/styles/withStyles';
import { Query } from 'react-apollo';
import mbpLogger from 'mbp-logger';
import { getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
// mobile header ui components
import MobileHeaderBlock from './MobileHeaderBlock';
import MobilePlantsHeaderBlock from './MobilePlantsHeaderBlock';
import CheckoutHeaderMobile from '../../../pages/Checkout/containers/CheckoutFood/components/Partials/CheckoutHeader/CheckoutHeaderMobile';
import ConnectedSearch from './Search';

// helpers, redux, gql
import { GRAPHQL_ENV } from '../../../gql';
import findHeaderAndCountries from '../../../gql/queries/findHeaderAndCountries';
import { getAddToCartPosition } from '../../../../state/ducks/App/App-Selectors';
import { setLocale } from '../../../../state/ducks/Member/ducks/Auth/helper/helper';
import SiteWideBanner from './SiteWideBanner/SiteWideBanner';
import EyebrowBanner from './EyebrowBanner';
import { getMilVetDealId } from '../VeteransAdvantagePage/helpers/utilities';

const TARGET_MOBILE = 'Mobile';

const styles = (theme) => ({
    appHeader: {
        color: theme.palette.grey?.['50'] || '#aeaeae',
        display: 'grid',
        gridTemplateColumns: '48px minmax(0, 60px) 1fr 60px 48px',
        gridTemplateRows: '60px',
        gridColumnGap: '0px',
        gridRowGap: '0px',
        width: '100%',
        boxSizing: 'border-box',
    },
    plantsAppHeader: {
        height: '65px',
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '0.5rem 1rem',
        color: '#6c6c6c !important',
        width: '100%',
        [theme.breakpoints.down(1026)]: {
            height: '110px',
            maxWidth: '960px',
            margin: '0 auto',
        },
        [theme.breakpoints.down(768)]: {
            height: '65px',
            maxWidth: '540px',
            margin: '0 auto',
        },
    },
    headerWrapper: {
        boxShadow: '0 3px 6px rgba(48,69,98,.16), 0 3px 6px rgba(48,69,98,.23)',
        backgroundColor: theme.palette.common?.white,
        width: '100%',
        zIndex: 1,
    },
    plantsHeaderWrapper: {
        marginTop: '35px',
        backgroundColor: '#f2f2f2ed',
        width: '100%',
        '&.hide-eyebrow': {
            marginTop: '0',
        },
    },
    stickyHeader: {
        position: 'fixed',
        top: 0,
        zIndex: 2,
    },
    notStickyHeader: {
        position: 'relative',
        top: 0,
        zIndex: 2,
    },
    plantsHeaderHide: {
        display: 'none',
    },
});

const getStickeyHeaderClassSet = (brand, classes, eyebrowVisible, isStickyHeader, isCheckoutLocation) => (`${brand?.code === 'PLA' ? `${classes.plantsHeaderWrapper} ${eyebrowVisible ? '' : 'hide-eyebrow'}` : classes.headerWrapper} ${isStickyHeader && !isCheckoutLocation ? classes.stickyHeader : classes.notStickyHeader}`);
const getHeaderContentClassSet = (isCheckoutVariation, isCheckoutLocation, classes, brand) => (isCheckoutVariation !== 'food' && isCheckoutLocation ? classes.appHeaderNone : `${brand?.code === 'PLA' ? classes.plantsAppHeader : classes.appHeader}`);
const renderHeaderContent = (block, toggleSearchBar, history, isCheckoutVariation, brand, uid, showSearch, featureFlags, isConfirmationPage, languageChange, countriesContent) => {
    const blockData = block;
    if (block.widgets?.type === '[SEARCH]') {
        blockData.widgets.onClick = toggleSearchBar;
    }
    if (history?.location?.pathname?.indexOf('/checkout/') > -1 && isCheckoutVariation === 'food' && brand['brand-id'] !== '08F') {
        return <CheckoutHeaderMobile brand={brand} block={blockData} />;
    }
    return (
        (brand?.code === 'PLA') ? <MobilePlantsHeaderBlock block={blockData} brand={brand} key={uid(blockData)} showSearch={showSearch} featureFlags={featureFlags} isConfirmationPage={isConfirmationPage} /> : <MobileHeaderBlock block={blockData} brand={brand} key={uid(blockData)} showSearch={showSearch} languageChange={languageChange} countriesContent={countriesContent} featureFlags={featureFlags} />
    );
};
const getConnectedSearch = (presentationFamily, enableSearch, brand) => (presentationFamily !== 'flower' ? enableSearch && <ConnectedSearch focusOnShow brand={brand} typeSearchBox="header" /> : null);
const getSiteWideBanners = (presentationFamily, siteWideBanners) => (presentationFamily === 'food' ? siteWideBanners?.length > 0 && <SiteWideBanner banners={siteWideBanners} /> : null);
const setEyebrow = (eyebrowBanner, brand, history, setIsEyebrowVisble) => {
    let eyebrowVisible = Object.keys(eyebrowBanner).length > 0;
    if (eyebrowBanner?.eyebrow_text) {
        eyebrowVisible = true;
        const vadealid = getMilVetDealId();
        if (brand.code === 'PLA') {
            eyebrowVisible = (history.location.pathname === '/' && eyebrowBanner?.display_section?.includes('Home'))
                            || (history.location.pathname.indexOf('/c/') >= 0 && eyebrowBanner?.display_section?.includes('Collection'))
                            || (history.location.pathname.indexOf('/p/') >= 0 && eyebrowBanner?.display_section?.includes('Product'))
                            || vadealid;
        }
        setIsEyebrowVisble(eyebrowVisible);
        return eyebrowVisible;
    }
    return false;
};
const MobileGraphqlHeader = (props) => {
    const {
        brand, classes, history, presentationFamily, layout, addToCartPosition, featureFlags, languageChange, setIsEyebrowVisble,
    } = props;
    // holds the condition to render search bar
    const [enableSearch, setEnableSearch] = useState(false);

    // This hook handles the sticky header state
    const [isStickyHeader, setStickyHeader] = useState(false);
    const stickyHeaderRef = useRef(null);

    const handleScroll = () => {
        if (typeof window !== 'undefined') {
            if (stickyHeaderRef && stickyHeaderRef.current) {
                const { height } = stickyHeaderRef.current.getBoundingClientRect();
                setStickyHeader(window.pageYOffset >= height);
            }
        }
    };
    const isCheckoutLocation = history?.location?.pathname.indexOf('/checkout/') > -1 && ['08F', 'CAN', 'PLA'].indexOf(brand['brand-id']) === -1;
    const isConfirmationPage = false;
    useEffect(() => {
        // Add scroll event when the component is loaded
        if (typeof document !== 'undefined') {
            document.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (typeof document !== 'undefined') {
                document.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    // hide the searchbar when add to cart button is fixed to header
    if (addToCartPosition === 'fixed' && enableSearch) {
        setEnableSearch(false);
    }

    const HEADER_QUERY = findHeaderAndCountries(brand, setLocale(), TARGET_MOBILE, layout);
    /**
     * findHeaderAndCountry - aggregator
     * mando a country tambien
     *
     */
    const toggleSearchBar = () => {
        setEnableSearch(!enableSearch);
    };

    return (
        <Query query={HEADER_QUERY}>
            {({ loading, error, data }) => {
                if (loading) {
                    return null; // skeleton component
                }

                if (error) {
                    mbpLogger.logError({
                        query: HEADER_QUERY,
                        component: 'MobileGraphqlHeader.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });
                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        query: HEADER_QUERY,
                        component: 'MobileGraphqlHeader.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }

                // No Entries
                if (!data?.findHeader?.content?.entries?.length) {
                    mbpLogger.logError({
                        message: 'No header blocks returned from API in MobileGraphqlHeader',
                        brand,
                    });

                    return null;
                }
                // data
                if (data?.findHeader?.content?.entries?.[0].header_blocks?.length) {
                    const headerBlocks = data.findHeader.content.entries[0].header_blocks;
                    const siteWideBanners = headerBlocks.filter((blocks) => Object.keys(blocks).includes('site_wide_banner'));
                    const isCheckoutVariation = featureFlags['which-checkout-variation'];
                    const showSearch = data.findHeader?.content?.entries?.[0]?.show_search || 'yes';
                    let eyebrowBanner = data.findHeader?.content?.entries?.[0].header_blocks.find((block) => Object.keys(block)[0] === 'eyebrow_banner');
                    eyebrowBanner = eyebrowBanner ? eyebrowBanner.eyebrow_banner : {};
                    const countriesContent = data?.findContent?.content?.entries?.[0] || {};

                    const eyebrowVisible = setEyebrow(eyebrowBanner, brand, history, setIsEyebrowVisble) || (!eyebrowBanner?.eyebrow_text && Object.keys(eyebrowBanner).length > 0);
                    return (
                        <>
                            {eyebrowVisible ? <EyebrowBanner eyebrowBanner={eyebrowBanner} brand={brand} /> : null}
                            <div className={getStickeyHeaderClassSet(brand, classes, eyebrowVisible, isStickyHeader, isCheckoutLocation)} ref={stickyHeaderRef} data-testid="is-sticky-header">
                                <div className={getHeaderContentClassSet(isCheckoutVariation, isCheckoutLocation, classes, brand)} id="headerContent">

                                    <UIDReset>
                                        <UIDConsumer>
                                            {(id, uid) => headerBlocks.map(
                                                (block) => (renderHeaderContent(block, toggleSearchBar, history, isCheckoutVariation, brand, uid, showSearch, featureFlags, isConfirmationPage, languageChange, countriesContent)),
                                            )}
                                        </UIDConsumer>
                                    </UIDReset>
                                </div>
                                {getConnectedSearch(presentationFamily, enableSearch, brand)}
                            </div>
                            {getSiteWideBanners(presentationFamily, siteWideBanners)}

                        </>
                    );
                }

                return <>No Header</>;
            }}
        </Query>
    );
};

const mapStateToProps = (state) => ({
    addToCartPosition: getAddToCartPosition(state),
    featureFlags: getFeatureFlags(state),
});

MobileGraphqlHeader.propTypes = {
    brand: shape({
        domain: string.isRequired,
    }).isRequired,
    classes: object.isRequired,  // added by withStyles HOC
    history: object.isRequired,
    presentationFamily: string.isRequired,
    layout: string.isRequired,
    addToCartPosition: string,
    featureFlags: shape({
        'which-checkout-variation': string,
    }).isRequired,
    languageChange: func.isRequired,
    setIsEyebrowVisble: func,
};

MobileGraphqlHeader.defaultProps = {
    addToCartPosition: null,
    setIsEyebrowVisble: () => {},
};
export default withRouter(connect(mapStateToProps)(withStyles(styles)(MobileGraphqlHeader)));
