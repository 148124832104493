/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Modal from '@material-ui/core/Modal';

const styles = (theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0',
    },
    modalContainer: {
        backgroundColor: theme.palette.white,
        width: '270px',
        minHeight: '200px',
        position: 'relative',
        overflow: 'auto',
    },
    modalSm: {
        minWidth: '280px',
        maxHeight: '250px',
        width: '26%',
        height: '80vh',
    },
    modalMd: {
        width: '55%',
        height: '80vh',
    },
    modalLg: {
        width: '90%',
        height: 'calc(100% - 100px)',
    },
    headingText: {
        color: theme.palette.colorPrimary,
        textTransform: 'uppercase',
        fontSize: '16px',
        lineHeight: '48px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        margin: '0 10px',
        padding: '0 4px',
        fontWeight: '700',
        borderBottom: `3px solid ${theme.palette.colorPrimary}`,
        textAlign: 'center',
    },
    alignTextLeft: {
        textAlign: 'left',
    },
    description: {
        background: theme.palette.white,
        overflowY: 'auto',
        padding: '5px 10px 0',
        '& a': {
            color: theme.palette.cta1,
            textDecoration: 'none',
        },
        '& h2': {
            fontSize: '0.9em',
            color: theme.palette.cta1,
            margin: '20px 0 7px',
        },
        '& img': {
            width: 'auto',
        },
        '& p': {
            marginTop: '0',
        },
    },
    right: {
        right: '24px',
        position: 'absolute',
        fontSize: '20px',
        fontWeight: '100',
        cursor: 'pointer',
    },

});

const ModalWrapper = (props) => {
    const {
        modalTitle, modalContent, classes, onCloseModal, isOpen, btnClose, alignItem, modalSize,
    } = props;
    let classTitle = classes.headingText;
    if (alignItem === 'left') {
        classTitle = `${classes.headingText} ${classes.alignTextLeft}`;
    }
    const modalContainerSize = () => {
        switch (modalSize) {
            case 'sm':
                return `${classes.modalContainer} ${classes.modalSm}`;
            case 'md':
                return `${classes.modalContainer} ${classes.modalMd}`;
            case 'lg':
                return `${classes.modalContainer} ${classes.modalLg}`;
            default:
                return classes.modalContainer;
        }
    };
    return (
        <div>
            <Modal id="modalWrapper" open={isOpen} className={classes.modal}>
                <div className={modalContainerSize()}>
                    <h1 className={classTitle}>
                        {modalTitle}
                        { btnClose && (<span className={classes.right} onClick={() => onCloseModal()} onKeyDown={() => { }} role="button" tabIndex={-1}>X</span>)}
                    </h1>
                    <div className={classes.description}>
                        {modalContent}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

ModalWrapper.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    modalTitle: PropTypes.string.isRequired,
    modalContent: PropTypes.element.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    btnClose: PropTypes.bool,
    alignItem: PropTypes.string,
    modalSize: PropTypes.string,
};

ModalWrapper.defaultProps = {
    btnClose: true,
    alignItem: 'center',
    modalSize: 'small',
};

export default withStyles(styles)(ModalWrapper);
