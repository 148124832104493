/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { getLogo } from '../../../../../../../../../state/ducks/Checkout/ducks/Common/Common-Selectors';
import { getCurrentBrandId } from '../../../../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';

// These are backup in case the logo has not been added in ContentStack.
const logos = {
    '18F': 'https://cdn2.1800flowers.com/wcsstore/Flowers/images/2018/mobile/flowers-stacked-logo.png',
    '18B': 'https://cdn2.1800baskets.com/wcsstore/RAPIDStorefrontAssetStore/images/ab/18b-logo-v2.svg',
    CCO: 'https://cdn2.cheryls.com/wcsstore/RAPIDStorefrontAssetStore/images/ab/cco-logo-v2.svg',
    HD: 'https://cdn2.harryanddavid.com/wcsstore/RAPIDStorefrontAssetStore/images/ab/hd-logo-v2.svg',
};

const styles = () => ({
    logo: {
        height: '41px',
        width: 'auto',
    },
});

const BrandLogo = (props) => {
    const {
        logo, classes, withLink, brand,
    } = props;

    const brandLogo = logo || logos[brand];

    if (withLink) {
        return (
            <img
                className={classes.logo}
                src={brandLogo}
                alt="Logo"
            />
        );
    }

    const homeUrl = (typeof window !== 'undefined') ? window?.location?.origin : '/';
    return (
        <a href={homeUrl} id="logo">
            <img
                className={classes.logo}
                src={brandLogo}
                alt="Logo"
            />
        </a>
    );
};

BrandLogo.propTypes = {
    classes: PropTypes.object.isRequired,
    withLink: PropTypes.bool,
    logo: PropTypes.string,
    brand: PropTypes.string,
};

BrandLogo.defaultProps = {
    withLink: false,
    brand: '',
    logo: '', // This fix console browser warnings
};
const mapStateToProps = (state) => ({
    logo: getLogo(state),
    brand: getCurrentBrandId(state),
});

export default compose(connect(mapStateToProps, null), withStyles(styles))(BrandLogo);
