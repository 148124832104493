/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { PureComponent } from 'react';
import {
    object, string, array, func,
} from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import mbpLogger from 'mbp-logger';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import withStyles from '@material-ui/core/styles/withStyles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ShoppingCartFull from '@material-ui/icons/ShoppingCartOutlined';

import { ducks as checkoutDucks } from '../../../../../state/ducks/Checkout';

const {
    common: { commonSelectors },
    cart: { cartOperations },
} = checkoutDucks;

const styles = (theme) => ({
    badge: {
    // The border color match the background color.
        border: `6px solid ${theme.palette.pink1}`,
        color: theme.palette.white,
        backgroundColor: theme.palette.pink1,
        height: '18px',
        width: '19px',
        minWidth: '12px',
        fontSize: '10px',
        right: '4px',
    },
    badgeHD: {
        top: '2px',
        left: '-1px',
        // The border color match the background color.
        color: theme.palette.colorIconHeader,
        backgroundColor: theme.palette.white,
        border: '0',
        height: '12px',
        width: '15px',
        minWidth: '15px',
        padding: '0',
        fontSize: '10px',
        textAlign: 'center',
        borderRadius: '50%',
    },
    cartIcon: {
        color: theme.palette.grey4,
        fontSize: '28px',
        display: 'block',
    },
    cartLinkStyle: {
        margin: 0,
        textDecoration: 'none',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    labelButton: {
        display: 'block',
    },
    titleIcon: {
        fontSize: '10px',
        fontFamily: theme.typography.fontFamily,
        display: 'block',
        textTransform: 'uppercase',
        color: theme.palette.colorIconHeader,
        textAlign: 'center',
    },
    cartIconHD: {
        color: theme.palette.colorIconHeader,
        fontSize: '24px',
        display: 'block',
        padding: '0px 0px 0px 2px',
    },
});

const getItemCount = (orderStatus, recipients) => {
    let itemCount = 0;

    try {
    // Ignore compeleted order data [STATUS = M]
        if (orderStatus !== 'M') {
            Object.keys(recipients).forEach((recipient) => {
                if (recipients[recipient].orderItems) {
                    const { orderItems } = recipients[recipient];

                    Object.keys(orderItems).forEach((orderItem) => {
                        itemCount += parseInt(orderItems[orderItem].quantity, 10);
                    });
                }
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            orderStatus,
            recipients,
            function: 'getItemCount',
            module: 'mbp-header',
            jsError: ex,
            message: 'getItemCount FAILED.',
        });
    }

    return itemCount;
};

export class MobileCartLink extends PureComponent {
    componentDidMount() {
        const { orderId, orderStatus, getCartUser } = this.props;

        if (!orderStatus && orderId) {
            getCartUser(orderId);
        }
    }

    render() {
        const {
            orderId, classes, recipients, orderStatus, brand,
        } = this.props;

        const itemCount = getItemCount(orderStatus, recipients) || 0;
        const MobileCartLinkBrands = ['HD', 'WLF', 'SY'];
        const isBrandHD = MobileCartLinkBrands.includes(brand.code);
        const cssCartIcon = isBrandHD ? classes.cartIconHD : classes.cartIcon;
        const cssBadge = isBrandHD ? classes.badgeHD : classes.badge;

        const pathToCart = itemCount ? `/checkout/cart/${orderId}` : '/checkout/cart';

        return (
            <Link
                to={pathToCart}
                className={classes.cartLinkStyle}
                alt="Mobile Cart"
                data-test="header-mobile-cart-link"
            >
                <IconButton
                    classes={{
                        label: classes.labelButton,
                    }}
                >
                    {itemCount ? (
                        <Badge
                            badgeContent={itemCount}
                            color="primary"
                            classes={{ badge: cssBadge }}
                            data-test="header-cart-badge"
                        >
                            {' '}
                            {/* do NOT change or remove data-test attribute */}
                            {isBrandHD ? (
                                <ShoppingCartFull className={cssCartIcon} />
                            ) : (
                                <ShoppingCartIcon className={cssCartIcon} />
                            )}
                        </Badge>
                    ) : (
                        <ShoppingCartIcon className={cssCartIcon} />
                    )}
                    {isBrandHD && <span className={classes.titleIcon}>Cart</span>}
                </IconButton>
            </Link>
        );
    }
}

MobileCartLink.propTypes = {
    brand: object.isRequired,
    classes: object.isRequired,
    recipients: array,
    orderId: string,
    orderStatus: string,
    getCartUser: func,
};

MobileCartLink.defaultProps = {
    recipients: [],
    orderId: '',
    orderStatus: null,
    getCartUser: () => {},
};

function mapStateToProps(state) {
    return {
        recipients: commonSelectors.getRecipients(state),
        orderId: commonSelectors.getOrderId(state),
        orderStatus: commonSelectors.getOrderStatus(state),
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCartUser: cartOperations.getCart,
        },
        dispatch,
    );
}
export const StyledMobileCartLink = withStyles(styles)(MobileCartLink);
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(StyledMobileCartLink),
);
