/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { PureComponent } from 'react';
import PropTypes, {
    string, shape, arrayOf, bool, func,
} from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import { withRouter } from 'react-router';
import mbpLogger from 'mbp-logger';

import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import loadable from '@loadable/component';
import memberDucks from '../../../../../state/ducks/Member/ducks';
import { selectors as appSelectors } from '../../../../../state/ducks/App';

import ConnectedSearch from '../../../GraphqlComponents/GraphqlHeader/Search';
import SearchMenuImg from '../../images/searchmenu.png';
import CheckoutHeaderIcon from '../../images/checkout-header.png';
// import CountryFinderBuilder from '../../../ContentTypeComponents/CountryFinder/CountryFinderBuilder';
const MenuBuilder = loadable(() => import(/* webpackChunkName: "MenuBuilder" */ './MenuBuilder/MenuBuilder'));

const {
    common: { commonSelectors },
} = memberDucks;

const styles = (theme) => {
    const mobileMenuBG = theme.palette.mobileMenuBG ? theme.palette.mobileMenuBG : null;
    return {
        signInLinkHeader: {
            color: '#000',
            width: '110%',
            display: 'block',
            textAlign: 'right',
            textDecoration: 'none !important',
            fontSize: '14px',
            paddingTop: '17px',
        },
        hide: {
            display: 'none',
        },
        searchForm: {
            padding: '10px',
            backgroundColor: theme.palette.cta1,
        },
        contDrawer: {
            overflowX: 'hidden',
        },
        drawer: {
            width: '100%',
            // background: mobileMenuBG ? mobileMenuBG.leftMenuBG : '#909090',
            height: '100%',
            fontFamily: theme.typography?.fontFamily,
            minWidth: '280px',
            maxWidth: '300px',
            fontWeight: '400',
        },
        leftMenu: {
            marginLeft: '0',
        },
        leftMenuPlantsStyle: {
            height: '100%',
        },
        menuIcon: {
            color: theme.palette.colorIconHeader,
            width: '30px',
        },
        titleIcon: {
            fontSize: '12px',
            fontFamily: theme.typography?.fontFamily,
            display: 'block',
            textTransform: 'initial',
            padding: '0 0 0 0',
            color: theme.palette.grey3,
            textAlign: 'center',
        },
        titleIconRaised: {
            fontSize: '10px',
            fontFamily: theme.typography?.fontFamily,
            display: 'block',
            textTransform: 'uppercase',
            color: theme.palette.colorIconHeader,
            textAlign: 'center',
        },
        rootButton: {
            minWidth: '40px',
            padding: '0 7px',
            textAlign: 'left',
        },
        labelButton: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '40px',
            margin: '0',
        },
        closeMenuRaised: {
            background: mobileMenuBG ? mobileMenuBG.leftMenuBG : '#909090',
            color: theme.palette.white,
            fontSize: '16px',
            width: '100%',
            padding: '10px 9px',
            textTransform: 'initial',
            fontWeight: 'normal',
            borderRadius: 'initial',
            '& hover': {
                background: mobileMenuBG ? mobileMenuBG.leftMenuBG : '#909090',
            },
        },
        closeTextMenuRaised: {
            color: 'white',
            display: 'inline-block',
            width: '90%',
            textAlign: 'left',
            fontWeight: '500',
        },
        closeIconMenuRaised: {
            display: 'inline-block',
            width: '10%',
            textAlign: 'right',
        },
        checkoutNortonIcon: {
            color: theme.palette.colorIconHeader,
            width: '60px',
            display: 'block',
            paddingLeft: '9px',
        },
    };
};

// TODO: replace these with feature flag
const menuHeaderBrands = ['HD', 'WLF', 'SY'];

//
// The "hamburger" menu displayed at top-left of screen
// It can take input from either graphql/CMS (PWA system) or composer (WCS-based).
// See MenuBuilder component for more details about menu array.
//
// Props:
//    menu               if PWA, hierarchical array of menu items (hdr.data.findHeader.content.entries[0].header_blocks[0].menu.menu_ref[0].menu_blocks)
//                       if composer, flat array of menu items
//    brand              brand object (must contain brand.code)
//    searchInputTerm    search string that user has entered (optional)
//
class LeftMenu extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
    // An error occurred.  Update state to tell renderer.
        return { hasError: true };
    }

    componentDidCatch(jsError, reactInfo) {
        const { menu, searchInputTerm } = this.props;
        mbpLogger.logError({
            message: `Unhandled Error in LeftMenu \n jsError = ${jsError} \n reactInfo = ${reactInfo} \n menu data ${menu} \n searchInputTerm = ${searchInputTerm}`,
            module: 'mbp-pwa-ui',
        });
    }

  handleClickMenu = (evt, value, category) => {
      if (evt && category === 'Languages') {
          evt.preventDefault();
      }
      const { languageChange } = this.props;
      this.setState((prevState) => ({
          open: !prevState.open,
      }));
      if (value && languageChange) {
          languageChange(value);
      }
  };

  renderMenuIcon = (brand, classes) => {
      if (menuHeaderBrands.includes(brand.code)) {
          return <MenuIcon className={classes.menuIcon} />;
      }

      return <img className={classes.menuIcon} src={SearchMenuImg} alt="Menu" />;
  };

  renderWelcomeClose = (brand, classes, searchInputTerm) => {
      if (menuHeaderBrands.includes(brand.code)) {
          return (
              <Button
                  className={classes.closeMenuRaised}
                  aria-label="Close Menu"
                  onClick={this.handleClickMenu}
                  data-test="nav-menu-close-button"
                  // classes={{
                  //     root: classes.rootButton,
                  //     label: classes.labelButton,
                  // }}
              >
                  <span className={classes.closeTextMenuRaised}>{brand['display-name']}</span>
                  <Close className={classes.closeIconMenuRaised}>close</Close>
              </Button>
          );
      }

      const { showSearch } = this.props;
      if (showSearch !== 'no') {
          return (
              <div className={classes.searchForm}>
                  <ConnectedSearch
                      languageId={-1}
                      brand={brand}
                      typeSearchBox="menu"
                      searchInputTerm={searchInputTerm}
                      searchButtonClick={this.handleClickMenu}
                  />
              </div>
          );
      }
      return null;
  };

  findLogoToRender = (menu) => {
      const logoBlock = menu.find((menuBlock) => Object.keys(menuBlock)[0] === 'logo');
      const logo = logoBlock?.logo;
      return logo || null;
  }

  /**
   * @description the function will return menu button for normal pages and norton checkout Icon for H&D payment and checkout pages
   * @param {Object} location: current location {required:location.path}
   * @param {Object} brand: props object used to identitfy the current brand {required:brand.code}
   * @param {Object} cssTitleIcon: css class object
   * @param {Object} searchInputTerm: param to renderMenuIcon
   * @param {Object} classes: css class object
   * @returns {JSX/null}: Menu Button/ Norton Icon
   */
  renderTopHeaderMenu = (
      pageFullyLoaded,
      brand,
      cssTitleIcon,
      searchInputTerm,
      classes,
      menuIcon,
      menu,
      isAuthenticated,
  ) => {
      const { history: { location: { pathname } } } = this.props;

      if (pathname) {
          if (pathname.match(/checkout/) && menuHeaderBrands.includes(brand.code)) {
              if (pathname.match(/checkout\/cart/) && !isAuthenticated) {
                  const isMenuLinks = menu.filter((menulink) => menulink?.menu_links);
                  const isMenuLinkInclude = isMenuLinks?.[0]?.menu_links?.menu_link.filter((menulink) => menulink);
                  const SignInLink = isMenuLinkInclude.filter((menulink) => menulink?.link?.title?.includes('Login'));

                  if (SignInLink.length > 0) {
                      return (
                          <Link className={classes.signInLinkHeader} href={SignInLink[0].link.href}>
                              SIGN IN
                          </Link>
                      );
                  }
                  return null;
              }
              return (
                  <img
                      className={classes.checkoutNortonIcon}
                      src={CheckoutHeaderIcon}
                      alt="Checkout-Header"
                  />
              );
          }

          if (menuIcon.name) {
              // menu icon passed from CMS
              return (
                  <button type="button" onClick={this.handleClickMenu} className={menuIcon.className} title="side menu" aria-label="side menu">
                      <img src={menuIcon.url} alt={menuIcon.description} aria-hidden />
                      {/* <span>{menuIcon.description}</span> */}
                  </button>
              );
          }

          return (
              <Button
                  className="IconButton"
                  onClick={this.handleClickMenu}
                  classes={{
                      root: classes.rootButton,
                      label: classes.labelButton,
                  }}
              >
                  {this.renderMenuIcon(brand, classes, searchInputTerm)}
                  <span className={cssTitleIcon} data-test="nav-menu-open-button-text">
                      Menu
                  </span>
              </Button>
          );
      }

      return null;
  };

  handleBackDropClick = () => {
      this.setState((prevState) => ({
          open: !prevState.open,
      }));
      return true;
  };

  render() {
      const {
          isBot,
          pageFullyLoaded,
          menu,
          menuIcon,
          classes,
          brand,
          searchInputTerm,
          isAuthenticated,
          languageChange,
          name,
          target,
          type,
          countriesContent,
      } = this.props;
      const { open, hasError } = this.state;
      const cssTitleIcon = menuHeaderBrands.includes(brand.code)
          ? classes.titleIconRaised
          : classes.titleIcon;
      const isPlants = brand.code === 'PLA';

      if (hasError) {
          return null;
      }
      // Pre-Render Menu for bots
      if (isBot) {
          return (
              <MenuBuilder
                  isBot={isBot}
                  menu={menu}
                  brand={brand}
                  handleClose={this.handleClickMenu}
                  targetPath={target}
                  name={name}
                  type={type}
                  target="Mobile"
              />
          );
      }

      return (
          <>
              {this.renderTopHeaderMenu(
                  pageFullyLoaded,
                  brand,
                  cssTitleIcon,
                  searchInputTerm,
                  classes,
                  menuIcon,
                  menu,
                  isAuthenticated,
              )}

              <Drawer classes={{ paper: classes.contDrawer }} open={open} onClose={this.handleClickMenu} disableBackdropClick onBackdropClick={this.handleBackDropClick}>
                  <div className={classes.drawer}>
                      {this.renderWelcomeClose(brand, classes)}
                      <div className={`${classes.leftMenu} ${isPlants && classes.leftMenuPlantsStyle}`}>
                          <MenuBuilder
                              targetPath={target}
                              name={name}
                              menu={menu}
                              type={type}
                              brand={brand}
                              logo={this.findLogoToRender(menu)}
                              handleClose={this.handleClickMenu}
                              target="Mobile"
                              languageChange={languageChange}
                              countriesContent={countriesContent}
                          />
                      </div>
                  </div>
              </Drawer>
          </>
      );
  }
}

LeftMenu.propTypes = {
    menu: arrayOf(shape({
        menu_links: shape({
            menu_link: arrayOf(shape({
                icon: string.isRequired,
                link: shape({
                    title: string.isRequired,
                    href: string.isRequired,
                }),
            })).isRequired,
        }),
        currency_widgets: shape({
            currency_group: arrayOf(shape({
                currency_name: string.isRequired,
                currency_value: string.isRequired,
                currency_symbol: string.isRequired,
            })).isRequired,
        }),
        menu_groups: shape({
            title: string.isRequired,
            menu_item: arrayOf(shape({
                icon: shape({
                    content_type: string,
                    file_size: string,
                    filename: string,
                    is_dir: bool,
                    title: string.isRequired,
                    uid: string,
                    url: string.isRequired,
                }),
                link: shape({
                    href: string.isRequired,
                    title: string.isRequired,
                }).isRequired,
            })),
        }),
    })).isRequired,
    searchInputTerm: PropTypes.string,
    brand: PropTypes.shape({
        domain: string.isRequired,
        code: string.isRequired,
    }).isRequired,
    classes: PropTypes.object.isRequired, // added by withStyles HOC
    isBot: PropTypes.bool,
    pageFullyLoaded: PropTypes.string,
    menuIcon: PropTypes.shape({
        description: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
    }),
    isAuthenticated: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    showSearch: PropTypes.string.isRequired,
    languageChange: func.isRequired,
    name: string.isRequired,
    target: string.isRequired,
    type: string.isRequired,
    countriesContent: shape({
        placeholder: string.isRequired,
        country_group: arrayOf(shape({
            country: shape({
                title: string,
                href: string,
            }),
            country_flag_image: shape({
                url: string,
            }),
        })).isRequired,
        text_color: string.isRequired,
    }).isRequired,
};
LeftMenu.defaultProps = {
    searchInputTerm: '',
    menuIcon: { name: null },
    pageFullyLoaded: 'false',
    isBot: false,
};

const mapStateToProps = (state) => ({
    isBot: appSelectors.getIsBot(state),
    isAuthenticated: commonSelectors.getIsAuthenticatedStatus(state),
    pageFullyLoaded: appSelectors.getPageFullyLoadedState(state),
});

export const StyledLeftMenu = withStyles(styles)(LeftMenu);
export default withRouter(
    connect(
        mapStateToProps,
        null,
    )(StyledLeftMenu),
);
