/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, string, bool, shape, arrayOf,
} from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import mbpLogger from 'mbp-logger';
import ConnectedCart from './Cart';
import MobileIconBlock from './MobileIconBlock';
import { LeftMenu } from '../../Header/MBPHeader';
import MobileSearchIconBlock from './MobileSearchIconBlock';
import { getCountryName } from '../../../../state/ducks/Member/ducks/Auth/helper/helper';
import { determineLanguagePrefixPath } from '../../../helpers/determineInternationalizationPath';
import Favorites from './Favorites';

let logoPlaced = false;

const headerLinkClassName = (classes) => {
    // check if logo has been placed
    // use class names with CSS Grid properites to position before or after the logo
    if (logoPlaced) {
        // if it has, this link goes after the logo (link2 has grid-column: 4)
        logoPlaced = false;
        return `${classes.headerBlock} ${classes.link2}`;
    }
    // if it has not, this link goes before the logo (link1 has grid-column: 2)
    return `${classes.headerBlock} ${classes.link1}`;
};

const styles = (theme) => ({
    headerBlock: {
        // CSS Grid
        alignSelf: 'center',
        justifySelf: 'center',

        // Flexbox
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        textAlign: 'center',
        fontSize: '14px',
        letterSpacing: '0.6px',
        textTransform: 'lowercase',
        color: theme.palette.cms?.mobileMenuTextColor || theme.palette.mobileMenuColor,
        cursor: 'pointer',
        textDecoration: 'none',
        height: '42px',
        fontFamily: theme.typography?.fontFamily,

        '& img': {
            width: '26px',
            height: 'auto',
        },
    },
    menu: {
        // CSS Grid
        gridRow: '1',
        gridColumn: '1',

        // Button Overrides
        appearance: 'none',
        border: 'none',
        background: 'none',
        fontSize: '10px',
        fontFamily: theme.typography?.secondaryFontFamily,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        letterSpacing: '.2px',
        borderRadius: '4px',
        color: theme.palette.common?.black,
        '& img': {
            width: '35px',
        },
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        [theme.breakpoints.down(768)]: {
            paddingLeft: '0',
            marginRight: '5px',
            paddingTop: '5px',
            paddingRight: '0',
        },
    },
    link1: {
        // CSS Grid
        gridRow: '1',
        gridColumn: '2',
        '& span': {
            display: 'none',
        },
        [theme.breakpoints.down(1026)]: {
            padding: '5px',
            '& img': {
                width: '20px',
                height: '20px',
            },
        },
    },
    logo: {
        // CSS Grid
        gridRow: '1',
        gridColumn: '3',

        width: 'auto',
        height: '100%',

        '& img': {
            width: 'auto',
            maxWidth: '136px',
            maxHeight: '45px',
        },
        [theme.breakpoints.down(768)]: {
            '& a': {
                left: '55px',
                transform: 'translateY(-5px)',
                position: 'absolute',
                textAlign: 'center',
                paddingBottom: '0',
                paddingTop: '0.34375rem',
                display: 'inline-block',
                lineHeight: 'inherit',
                marginRight: '1rem',
            },
        },
    },
    link2: {
        // CSS Grid
        gridRow: '1',
        gridColumn: '4',
        '& span': {
            display: 'none',
        },
        [theme.breakpoints.down(1026)]: {
            marginLeft: 'auto',
            padding: '5px',
            '& img': {
                width: '20px',
                height: '20px',
            },
        },
        [theme.breakpoints.down(768)]: {
            marginLeft: 'auto',
            '& span': {
                display: 'none',
            },
        },
    },
    cart: {
        // CSS Grid
        gridRow: '1',
        gridColumn: '4',
        fontSize: '10px',
        fontFamily: theme.typography?.secondaryFontFamily,
        textTransform: 'uppercase',
        letterSpacing: '.2px',
        borderRadius: '50%',
        padding: '12px',
        height: '100%',
        '& img': {
            width: '24px',
        },
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    search: {
        gridRow: '1',
        gridColumn: '4',
        fontSize: '10px',
        fontFamily: theme.typography?.secondaryFontFamily,
        textTransform: 'uppercase',
        letterSpacing: '.2px',
        borderRadius: '50%',
        border: 'none',
        background: 'none',
        outline: 'none',
        padding: '12px',
        height: '100%',
        '& img': {
            width: '24px',
        },
        '&:hover': {
            textDecoration: 'none',
        },
        '& svg': {
            fontSize: '24px',
        },
    },
});

const MobileHeaderBlock = ({
    block, brand, classes, showSearch, isConfirmationPage,
}) => {
    const blockKey = Object.keys(block)[0];
    const blockObj = block[blockKey];
    if (!blockObj) return null;
    if (blockKey === 'menu' && blockObj?.url !== '/') {
        const country = getCountryName();
        if (blockObj?.url !== country) {
            return null;
        }
    }

    switch (blockKey) {
        case 'menu':
            return (
                <LeftMenu
                    name={blockObj.name}
                    target={blockObj.url}
                    type={blockObj.type}
                    menu={blockObj.menu_ref?.[0]?.menu_blocks || 'default left menu'}
                    brand={brand}
                    menuIcon={{
                        name: blockObj.name,
                        url: blockObj.menu_icon?.url,
                        description: blockObj.name,
                        className: `${classes.headerBlock} ${classes.menu}`,
                    }}
                    showSearch={showSearch}
                />
            );

        case 'header_links':
            if (isConfirmationPage && blockObj.link?.title !== 'Shopping Cart') {
                return null;
            }
            if (blockObj?.link?.title === 'Favorites') {
                return (
                    <Favorites />
                );
            }
            return (
                <MobileIconBlock
                    name={blockObj.link?.title}
                    linkHref={blockObj.link?.href}
                    description={blockObj.link?.title}
                    imgSrc={blockObj.icon?.url}
                    className={headerLinkClassName(classes)}
                />
            );

        case 'logos':
            logoPlaced = true;
            return (
                <Link to={determineLanguagePrefixPath('/')} className={`${classes.headerBlock} ${classes.logo}`} aria-label="brand-link" aria-labelledby="brand-link">
                    <img src={blockObj.image?.url} alt={blockObj?.title} />
                </Link>
            );

        case 'widgets':
            if (blockObj.name === 'Shopping Cart') {
                return (
                    <ConnectedCart
                        image={blockObj.icon && blockObj.icon?.url ? blockObj.icon?.url : null}
                        brand={brand}
                        name={blockObj.link?.title}
                        description={blockObj.link?.title}
                        type="mobile"
                        isConfirmationPage={isConfirmationPage}
                    />
                );
            }
            if (blockObj?.type === '[SEARCH]' && showSearch === 'yes') {
                return (
                    <MobileSearchIconBlock
                        name={blockObj.link?.title}
                        onSearchClick={blockObj.onClick}
                        className={`${classes.headerBlock} ${classes.search}`}
                    />
                );
            }
            return (
                <MobileIconBlock
                    name={blockObj.link?.title}
                    linkHref={blockObj.link?.href}
                    description={blockObj.link?.title}
                    imgSrc={blockObj.icon && blockObj.icon?.url ? blockObj.icon?.url : null}
                    className={`${classes.headerBlock} ${classes.cart}`}
                />
            );
        case 'eyebrow_banner':
            return null;
        default:
            mbpLogger.logError({
                message: `Unknown header block type '${blockKey}' in MobileHeaderBlock`,
                brand,
                block: blockObj,
            });
            return null;
    }
};

MobileHeaderBlock.propTypes = {
    block: shape({
        logos: shape({
            image: shape({
                content_type: string,
                file_size: string,
                filename: string,
                is_dir: bool,
                title: string.isRequired,
                uid: string,
                url: string.isRequired,
            }).isRequired,
            link: shape({
                href: string.isRequired,
                title: string.isRequired,
            }).isRequired,
        }),
        widgets: shape({
            icon: shape({
                content_type: string,
                file_size: string,
                filename: string,
                is_dir: bool,
                title: string.isRequired,
                uid: string,
                url: string.isRequired,
            }).isRequired,
            link: shape({
                href: string.isRequired,
                title: string.isRequired,
            }).isRequired,
            name: string.isRequired,
        }),
        menu: shape({
            menu_icon: shape({
                content_type: string,
                file_size: string,
                filename: string,
                is_dir: bool,
                title: string.isRequired,
                uid: string,
                url: string.isRequired,
            }).isRequired,
            menu_ref: arrayOf(shape({
                menu_blocks: arrayOf(shape({
                    menu_links: shape({
                        menu_link: arrayOf(shape({
                            icon: string.isRequired,
                            link: shape({
                                title: string.isRequired,
                                href: string.isRequired,
                            }),
                        })).isRequired,
                    }),
                    currency_widgets: shape({
                        currency_group: arrayOf(shape({
                            currency_name: string.isRequired,
                            currency_value: string.isRequired,
                            currency_symbol: string.isRequired,
                        })).isRequired,
                    }),
                    menu_groups: shape({
                        title: string.isRequired,
                        menu_item: arrayOf(shape({
                            icon: shape({
                                content_type: string,
                                file_size: string,
                                filename: string,
                                is_dir: bool,
                                title: string.isRequired,
                                uid: string,
                                url: string.isRequired,
                            }),
                            link: shape({
                                href: string.isRequired,
                                title: string.isRequired,
                            }).isRequired,
                        })),
                    }),
                })).isRequired,
            })).isRequired,
        }),
    }).isRequired,
    classes: object.isRequired,
    brand: shape({
        code: string.isRequired,
    }).isRequired,
    showSearch: string.isRequired,
    isConfirmationPage: bool.isRequired,
};

export default withStyles(styles)(MobileHeaderBlock);
