/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { string } from 'prop-types';

const MobileIconBlock = ({
    imgSrc, linkHref, description, className,
}) => (
    <a href={linkHref} className={className} aria-label={description} aria-labelledby={description}>
        <img src={imgSrc} alt={description} aria-hidden="true" />
        <span>
            {description}
        </span>
    </a>
);

MobileIconBlock.propTypes = {
    description: string.isRequired,
    linkHref: string.isRequired,
    imgSrc: string.isRequired,
    className: string.isRequired,
};

export default MobileIconBlock;
