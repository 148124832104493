/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { object, func } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { determineLanguagePrefixPath } from '../../../../../../helpers/determineInternationalizationPath';
import { getBrand } from '../../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';

const styles = (theme) => ({
    modalContainer: {
        padding: '0',
        '&:focus': {
            outline: 'none',
        },
    },
    headerModalText: {
        textAlign: 'center',
        fontWeight: '700',
        marginBottom: '15px',
    },
    btnContinue: {
        backgroundColor: theme.palette.primaryButton,
        color: theme.palette.colorPrimaryContrast,
        fontSize: '14px',
        fontWeight: 'bold',
        margin: '10px auto',
        display: 'block',
        width: '220px',
        '&:hover, &:focus, &:active': {
            backgroundColor: theme.palette.primaryButton,
        },
    },
    btnContinuePlants: {
        backgroundColor: theme.palette.grayButton,
        color: theme.palette.black,
        fontSize: '14px',
        fontWeight: 'bold',
        margin: '10px auto',
        display: 'block',
        width: '220px',
        '&:hover, &:focus, &:active': {
            backgroundColor: theme.palette.primaryButtonHover,
        },
    },
    btnGoHome: {
        backgroundColor: theme.palette.colorPrimary,
        color: `${theme.palette.white} !important`,
        fontSize: '14px',
        fontWeight: 'bold',
        margin: '10px auto',
        display: 'block',
        width: '220px',
        padding: '10px',
        textAlign: 'center',
        '&:hover, &:focus, &:active': {
            backgroundColor: theme.palette.colorPrimary,
        },
    },
    btnGoHomePlants: {
        backgroundColor: theme.palette.primaryButton,
        color: `${theme.palette.black} !important`,
        fontSize: '14px',
        fontWeight: 'bold',
        margin: '10px auto',
        display: 'block',
        width: '220px',
        padding: '10px',
        textAlign: 'center',
        '&:hover, &:focus, &:active': {
            backgroundColor: theme.palette.primaryButtonHover,
        },
        borderRadius: '4px',
    },
});

const ModalContent = ({ classes, closeModal, dataDictionary }) => {
    const brand = useSelector(getBrand);
    const isPlants = brand.code === 'PLA';
    return (
        <div className={classes.modalContainer}>
            <div className={classes.headerModalText}>{dataDictionary?.['Are you sure you want to leave?'] || 'Are you sure you want to leave?'}</div>
            <Button
                id="btn-continue-checkout"
                name="continueCheckoutButton"
                className={isPlants ? classes.btnContinuePlants : classes.btnContinue}
                aria-label="NO, CONTINUE CHECKOUT"
                onClick={closeModal}
            >
                {dataDictionary?.['NO, CONTINUE CHECKOUT'] || 'NO, CONTINUE CHECKOUT'}
            </Button>
            <Link
                id="btn-go-to-homepage"
                name="goToHomePageButton"
                className={isPlants ? classes.btnGoHomePlants : classes.btnGoHome}
                aria-label="YES (GO TO HOMEPAGE)"
                to={determineLanguagePrefixPath('/')}
            >
                {dataDictionary?.['YES (GO TO HOMEPAGE)'] || 'YES (GO TO HOMEPAGE)'}
            </Link>
        </div>
    );
};

ModalContent.defaultProps = {
    dataDictionary: {},
};

ModalContent.propTypes = {
    classes: object.isRequired,
    closeModal: func.isRequired,
    dataDictionary: object,
};

export default withStyles(styles)(ModalContent);
