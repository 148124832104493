/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import SearchImg from '@material-ui/icons/Search';

const styles = (theme) => ({
    searchIconTop: {
        width: '100%',
        margin: '0',
        color: theme.palette.colorIconHeader,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    iconText: {
        fontSize: '9px',
        display: 'block',
        lineHeight: '1',
        textTransform: 'uppercase',
        textAlign: 'center',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
});
const searchIconLabel = 'Search';
export class SearchIcon extends PureComponent {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.searchIconTop}>
                <SearchImg />
                <span className={classes.iconText}>{searchIconLabel}</span>
            </div>
        );
    }
}

SearchIcon.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchIcon);
