/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { getLogo } from '../../../../../../../../state/ducks/Checkout/ducks/Common/Common-Selectors';

const styles = (theme) => ({
    logo: {
        height: 'auto',
        width: '136px',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
            maxWidth: '136px',
            width: 'auto',
            maxHeight: '45px',
        },
    },
});

const BrandLogo = (props) => {
    const {
        logo, classes, withLink, brand, brandLogos,
    } = props;
    const brandLogo = brandLogos[brand] || logo;

    if (withLink) {
        return (
            <img
                className={classes.logo}
                src={brandLogo}
                alt="Logo"
            />
        );
    }

    const homeUrl = (typeof window !== 'undefined') ? window?.location?.origin : '/';
    return (
        <a href={homeUrl}>
            <img
                className={classes.logo}
                src={brandLogo}
                alt="Logo"
            />
        </a>
    );
};

BrandLogo.propTypes = {
    classes: PropTypes.object.isRequired,
    withLink: PropTypes.bool,
    logo: PropTypes.string,
    brand: PropTypes.string,
    brandLogos: PropTypes.object.isRequired,
};

BrandLogo.defaultProps = {
    withLink: false,
    brand: '',
    logo: '', // This fix console browser warnings
};
const mapStateToProps = (state) => ({
    logo: getLogo(state),
});

export default compose(connect(mapStateToProps, null), withStyles(styles))(BrandLogo);
